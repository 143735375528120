import React from 'react'

import Doctors from '../../Component/Patient/Doctors';
function Home() {
  return (
    <div>
       <Doctors/>
    </div>
  )
}

export default Home
