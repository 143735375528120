import React from 'react'
import Navbar from  '../../Component/home/Navbar';
import Doctor from '../../Component/home/doctors';
import FooterH from '../../Component/home/footer'
function Home() {
  return (
    <div>
       <Navbar/>
       <Doctor/>
       <FooterH/>
    </div>
  )
}

export default Home
