import React from 'react'
import Navbar from  '../../Component/home/Navbar';
import Logindoctor from '../../Component/home/doctorlogin';
import FooterH from '../../Component/home/footer'
function Login() {
  return (
    <div>
       <Navbar/>
       <Logindoctor/>
       <FooterH/>
    </div>
  )
}

export default Login
