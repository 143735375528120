import React from 'react'
import Patient from '../../Component/Doctor/Dashboard';
function Home() {
  return (
    <div>
       <Patient/>
    </div>
  )
}

export default Home
