import React from 'react'

import Appointment from '../../Component/Doctor/appointments';
function Home() {
  return (
    <div>
       <Appointment/>
    </div>
  )
}

export default Home
