import React from 'react'
import AvailabilityComponenet from '../../Component/Doctor/availability'
function Availability() {
  return (
    <div>
      <AvailabilityComponenet/>
    </div>
  )
}

export default Availability
