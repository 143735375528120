import React from 'react'
import Navbar from  '../../Component/home/Navbar';
import Signup1 from '../../Component/home/Signup';
import FooterH from '../../Component/home/footer'
function Signup() {
  return (
    <div>
      <Navbar/>
       <Signup1/>
       <FooterH/>
    </div>
  )
}

export default Signup
