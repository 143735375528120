import React from 'react'

import Patient from '../../Component/Patient/Patients';
function Home() {
  return (
    <div>
       <Patient/>
    </div>
  )
}

export default Home
