import React from 'react'
import Navbar from  '../../Component/home/Navbar';
import Otp from '../../Component/home/otp';
import FooterH from '../../Component/home/footer'
function Login() {
  return (
    <div>
       <Navbar/>
       <Otp/>
       <FooterH/>
    </div>
  )
}

export default Login
