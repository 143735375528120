import React from 'react'
// import Navbar from  '../../Component/Patient/Sidebar';
import Patient from '../../Component/Patient/Dashboard';
import FooterH from '../../Component/home/footer'
function Home() {
  return (
    <div>
       <Patient/>

    </div>
  )
}

export default Home
