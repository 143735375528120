import React from 'react'
import Navbar from  '../../Component/home/Navbar';
import Login1 from '../../Component/home/login';
import FooterH from '../../Component/home/footer'
function Login() {
  return (
    <div>
       <Navbar/>
       <Login1/>
       <FooterH/>
    </div>
  )
}

export default Login
