import React from 'react'

import Appointment from '../../Component/Patient/Appointments';
function Home() {
  return (
    <div>
       <Appointment/>
    </div>
  )
}

export default Home
