import React from 'react'
import Admin from '../../Component/Admin/Dashboard';
function Home() {
  return (
    <div>
       <Admin/>
    </div>
  )
}

export default Home
