import React from 'react'
import Navbar from  '../../Component/home/Navbar';
import HomeM from '../../Component/home/home';
import FooterH from '../../Component/home/footer'
function Home() {
  return (
    <div>
       <Navbar/>
       <HomeM/>
       <FooterH/>
    </div>
  )
}

export default Home
